import { useEffect } from "react";
import { FormModal } from "../../../../../../../components/form/FormModal/FormModal";
import { BaseIcon } from "../../../../../../../components/BaseIcon/BaseIcon";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../../../../../components/Button/Button";
import { useForm } from "react-hook-form";
import SampleIcon from "../../../../../../../assets/sample.svg";
import styles from "./SampleModal.module.css";
import FormInput from "../../../../../../../components/form/FormInput/FormInput";
import { bodyLocations } from "../../../../After/ProcessCapscan/Sample/Sample";
import ImageCapture from "../../../../../../../components/ImageCapture/ImageCapture";
import { TUBES_DEVICE } from "../../../CameraSettings/CameraSettings";
import useSample from "../../../../../../../hooks/useSample";
import { makeDirtyObject } from "../../../../../../../util/form.util";

export type SampleProps = {
  id: string;
  studyLetter?: string;
  sampleNumber?: number;
  pH?: string;
  suffix?: string;
  volume?: string;
  bodyLocation?: string;
  imageUrl?: string;
  capscanSn: string;
};

export type SampleParams = {
  handleHide: () => void;
  refetch: () => void;
};
const SampleCapscanModal = (props: SampleProps & SampleParams) => {
  const { control, handleSubmit, reset, getValues, getFieldState } =
    useForm<SampleProps>();
  const { updateSample } = useSample();
  const intl = useIntl();

  const onSubmit = async (formProps: SampleProps) => {
    const { ...rest } = makeDirtyObject(
      (key) => getFieldState(key).isDirty,
      formProps
    );

    const response = await updateSample({
      ...rest,
      capscanSn: props.capscanSn,
      id: props.id,
    });
    if (response?.success) {
      props.refetch();
      props.handleHide();
    } else {
      alert(response?.message);
    }
  };

  useEffect(() => {
    reset(props);
  }, [props, reset]);

  const Sample = () => (
    <div className={`d-flex flex-column ${styles.main} ms-3 mt-5`}>
      <div className="d-flex flex-column">
        <div className="d-flex ms-3">
          <label>
            <FormattedMessage
              id="ENVIVO.SAMPLE.SUFFIX"
              defaultMessage="Suffix"
            />
            :
          </label>
          <label className="ms-1">
            <b> {props.suffix}</b>
          </label>
        </div>
        <div className="d-flex">
          <div className={styles.inputBox}>
            <FormInput
              type="text"
              name={`pH`}
              control={control}
              rules={{
                required: true,
                min: 0,
              }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.SAMPLE.PH",
                  defaultMessage: "pH",
                }),
                type: "number",
                className: styles.input,
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <FormInput
              type="select"
              name={`bodyLocation`}
              control={control}
              rules={{
                required: true,
              }}
              input={{
                items: bodyLocations.map((item) => ({
                  key: item,
                  value: item,
                })),
                placeholder: intl.formatMessage({
                  id: "ENVIVO.SAMPLE.BODY_LOCATION",
                  defaultMessage: "Location in the body",
                }),
                className: `${styles.input} ${styles.location}`,
              }}
            />
          </div>
          <div className={styles.inputBox}>
            <FormInput
              type="text"
              name={`volume`}
              control={control}
              rules={{
                min: 0,
              }}
              input={{
                placeholder: intl.formatMessage({
                  id: "ENVIVO.SAMPLE.VOLUME",
                  defaultMessage: "Volume",
                }),
                type: "text",
                className: styles.input,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <FormModal
        onSubmit={handleSubmit(onSubmit)}
        id="sample"
        className={`modal-xl modal-dialog-centered modal-dialog-scrollable mt-5`}
        header={
          <div>
            <BaseIcon icon={SampleIcon} />{" "}
            <b>
              <FormattedMessage id="ENVIVO.SAMPLE" defaultMessage="Sample" />
            </b>
          </div>
        }
        footer={
          <>
            <Button
              variant="outline-dark"
              className="border-dark"
              onClick={props.handleHide}
            >
              <FormattedMessage
                id="ENVIVO.MODAL.CANCEL"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              variant="outline-dark"
              className="border-dark"
              form="sample"
              type="submit"
            >
              <FormattedMessage id="BUTTON.SAVE" defaultMessage={"Save"} />
            </Button>
          </>
        }
        show={true}
        handleClose={props.handleHide}
      >
        <div>
          <div className={`${styles.imgBox} d-flex`}>
            <Sample />
            <div className="ms-5">
              <ImageCapture
                width={250}
                height={350}
                disabled={false}
                imageUrl={getValues("imageUrl")}
                handleCapture={() => {}}
                deviceId={localStorage.getItem(TUBES_DEVICE)}
                className={styles.image}
                scale={3}
                mirrored={true}
              />{" "}
            </div>
          </div>
        </div>
      </FormModal>
    </>
  );
};

export default SampleCapscanModal;
