import { KitSampleDto } from "../../api";
import { getFormattedDate } from "../../util/date.util";
import { bodyItems } from "../envivo/Home/After/ProcessOther/ProcessSample/ProcessSample";

export const columns = ["tid", "kitNumber", "metadata", "capScan", "saliva", "plasma", "kitStatus", "comment", "actions"];

export const capScapColumns = [" ", "ingestedDate", "ingestedTime", "recoveredDate", "recoveredTime", "transitTime", "storageDate", "storageTime", "storageTemperature", "waterAdded", "capScanType"];

export const generateHeaders = (getTitle: (prefix: string, name: string) => JSX.Element) => {
  return columns.map((column: string) => {
    switch (column) {
      case "capScan": {
        return (
          <th colSpan={capScapColumns.length} style={{ backgroundColor: "lightblue" }}>
            {getTitle("CLINICAL.TABLE.CAPSCANS.COLUMN.CAPSCAN", "Capscan")}
          </th>
        );
      }
      case "saliva": {
        return (
          <th colSpan={5} style={{ backgroundColor: "lightskyblue" }}>
            {getTitle("CLINICAL.TABLE.CAPSCANS.COLUMN.SALIVA", "Saliva")}
          </th>
        );
      }
      case "plasma": {
        return (
          <th colSpan={5} style={{ backgroundColor: "lightskyblue" }}>
            {getTitle("CLINICAL.TABLE.CAPSCANS.COLUMN.PLASMA", "Plasma")}
          </th>
        );
      }

      default:
        return (
          <th rowSpan={2} style={{ backgroundColor: "lightgray" }}>
            {getTitle("CLINICAL.TABLE.COLUMN", column)}
          </th>
        );
    }
  });
};

export const generateCapscanHeaders = (getTitle: (prefix: string, name: string) => JSX.Element) => {
  return capScapColumns.map((col) => (
    <th style={{ backgroundColor: "lightblue", top: "40px" }}>{col === "capScanType" ? "CapScan Type" : getTitle(`CLINICAL.TABLE.CAPSCANS.COLUMN_${col.toUpperCase()}`, col)}</th>
  ));
};

export const generateSampleHeaders = (getTitle: (prefix: string, name: string) => JSX.Element) => {
  return (
    <>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}> {getTitle("CLINICAL.TABLE.SAMPLES.COLUMN.COLLECTION_DATE", "Collection Date")}</th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>{getTitle("CLINICAL.TABLE.SAMPLES.COLUMN.COLLECTION_TIME", "Collection Time")}</th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>{getTitle("CLINICAL.TABLE.SAMPLES.COLUMN.COLLECTION_STORAGE_DATE", "Storage Date")}</th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>{getTitle("CLINICAL.TABLE.SAMPLES.COLUMN.COLLECTION_STORAGE_TIME", "Storage Time")}</th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>{getTitle("CLINICAL.TABLE.SAMPLES.COLUMN.COLLECTION_TEMPERATURE", "Storage Temperature")}</th>
    </>
  );
};

export const generateOtherSamplesHeaders = (getTitle: (prefix: string, name: string) => JSX.Element) => {
  return (
    <>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}> </th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>Stool</th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>Saliva</th>
      <th style={{ backgroundColor: "lightskyblue", top: "40px" }}>Plasma</th>
    </>
  );
};

export const getSampleString = (sample: KitSampleDto) => {
  const { storageDate, temperature, stoolScore, sampleType } = sample;

  const stoolScoreStr = Number(sampleType) === bodyItems.indexOf("Stool") ? `${stoolScore}` : "";

  const storage = !storageDate ? "" : `${storageDate && getFormattedDate(storageDate)}`;

  const temperatureStr = !temperature ? "" : `${temperature}`;

  return `Storage date: ${storage}, Temperature: ${temperatureStr}, Bristol Stool Score: ${stoolScoreStr}`;
};

// export const generatePlasmaHeaders = (
//   getTitle: (prefix: string, name: string) => JSX.Element
// ) => {
//   return capScapColumns.map((capscan) => (
//     <th style={{ backgroundColor: "lightblue", top: "40px" }}>
//       {capscan === "capScanType"
//         ? "CapScan Type"
//         : getTitle("CLINICAL.TABLE.CAPSCANS.COLUMN", capscan)}
//     </th>
//   ));
// };
