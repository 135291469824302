import { useEffect, useRef, useState } from "react";
import styles from "./ReportTable.module.css";
import useTableHelper from "../../../../../../hooks/useTableHelper";
import Table, { DataType } from "../../../../../../components/Table/Table";
import { filterSamplesQuery } from "../../../../../../queries/sampleReport.query";
import { useMutation, useQuery } from "@tanstack/react-query";
import FormInput from "../../../../../../components/form/FormInput/FormInput";
import { Button } from "../../../../../../components/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-router-dom";
import { useForm } from "react-hook-form";
import { studyNamesQuery } from "../../../../../../queries/study.query";
import { filterSampleMutation } from "../../../../../../mutations/sampleReports.mutation";
import { FilterSamplesParams } from "../../../../../../models/requests/sampleReport.request";
import { useReactToPrint } from "react-to-print";
import { Row } from "react-table";
import EditIcon from "../../../../../../assets/edit.svg";
import SampleCapscanModal from "./SampleCapscan/SampleCapscanModal";
import { FullSampleDataDto } from "../../../../../../api";
import ExpandedRow from "./ExpandedRow";
import { useExpanded } from "react-table";
import { usePagination } from "react-table";
import {
  convertToCSV,
  getCapscanColor,
} from "../../../../../../util/shared.util";
import {
  getFormattedLocalDate,
  getTotalHoursBetweenDates,
} from "../../../../../../util/date.util";
import { sexList } from "../../../../../clinical/ClinicalRow/Metadata/General";

export type FilterSamplesFormInputs = {
  study: string;
  patient: string;
  includeImages: boolean;
  deviceDimensions: { width: number; height: number };
  sampleDimensions: { width: number; height: number };
};

export const columnsNames = [
  "capscanSn",
  "clinic",
  "clinicDepartment",
  "pH",
  "sampleNumber",
  "subjectId",
  "bodyLocation",
  "suffix",
  "volume",
  "boxBarcode",
  "boxPosition",
  "freezer",
  "rack",
  "links",
  "ingestedDate",
  "recoveredDate",
  "transitTime",
  "reference",
  "sex",
  "age",
  "weight",
  "height",
  "medicines",
  "conditions",
];

const CapscanSampleReportTable = () => {
  const intl = useIntl();
  const [renderImage, setRenderImage] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null);
  const { mutateAsync: mutateFilterSample } = useMutation(
    filterSampleMutation()
  );
  const { watch, handleSubmit, control } = useForm<FilterSamplesFormInputs>();
  const { data: studyNames } = useQuery(studyNamesQuery());
  const { getColumns } = useTableHelper();
  const [selected, setSelected] = useState<FullSampleDataDto | undefined>(
    undefined
  );
  const [showModal, setShowModal] = useState(false);
  const watchStudy = watch("study");
  const watchPatient = watch("patient");
  const watchIncludeImages = watch("includeImages");

  const { data: response, refetch: filterRefetch } = useQuery(
    filterSamplesQuery({
      study: watchStudy,
      patient: watchPatient,
      includeImages: watchIncludeImages || false,
    })
  );

  useEffect(() => {
    if (tableRef.current && renderImage) {
      handlePrint();
    }
  }, [tableRef.current, renderImage]);

  const getStudies = () =>
    studyNames?.data?.map((item) => ({
      key: item.id,
      value: item.studyName,
    }));

  const getData = () => {
    if (response?.data) {
      const { data } = response;

      const getSampleNumber = (sample: FullSampleDataDto) => {
        if (sample.suffix === "None") {
          return "No_sample";
        }
        return (
          sample.studyLetter
            ?.concat(sample.sampleNumber.toString().padStart(4, "0"))
            .concat(sample.suffix) ||
          sample.sampleNumber.toString().padStart(4, "0").concat(sample.suffix)
        );
      };

      const getTransitTime = (sample: FullSampleDataDto) => {
        if (sample.ingestedDate && sample.recoveredDate) {
          return `${getTotalHoursBetweenDates(
            sample.ingestedDate,
            sample.recoveredDate
          )} hrs`;
        }
        return null;
      };

      return data!.map((sample) => ({
        ...sample,
        links: sample.links?.map((link) => link.link).join(", "),
        boxBarcode: sample.box?.boxBarcode,
        boxPosition: sample.box?.position,
        freezer: sample.box?.freezerNumber,
        rack: sample.box?.rackNumber,
        box: undefined,
        metadata: undefined,
        reference: `${sample.reference} ${getCapscanColor(sample.reference)}`,
        sampleNumber: getSampleNumber(sample),
        transitTime: getTransitTime(sample),
        recoveredDate:
          sample.recoveredDate &&
          getFormattedLocalDate(sample.recoveredDate, "YYYY-MM-DD HH:mm"),
        ingestedDate:
          sample.ingestedDate &&
          getFormattedLocalDate(sample.ingestedDate, "YYYY-MM-DD HH:mm"),
        age: sample.metadata?.dateOfBirth,
        sex: sexList[sample.metadata?.sex],
        conditions: sample.metadata?.conditions
          ?.map((cond) => `${cond.key}: ${cond.value}`)
          .join(", "),
        medicines: sample.metadata?.medicine
          ?.map((med) => med.value)
          .join(", "),
        weight: sample.metadata?.weight,
        height: sample.metadata?.height,
      }));
    }
    return [];
  };

  const findSelectedItemById = (row?: Row<DataType>, items?: any[]) =>
    items?.find((item) => item.id === row?.original?.id);

  const handleRowActionClick = (
    setShowModal: (show: boolean) => void,
    setSelectedItem: (item: any) => void,
    setSelectedRow: (row?: Row<DataType>) => void,
    row?: Row<DataType>,
    items?: any[]
  ) => {
    setSelectedItem(findSelectedItemById(row, items) || undefined);
    setShowModal(true);
    setSelectedRow(undefined);
  };

  const onFilterSubmit = async (sample: FilterSamplesParams) => {
    await mutateFilterSample(
      { ...sample },
      {
        onSuccess: async (response) => {
          if (response) {
            if (!response.success) alert(response.message);
            else {
              await filterRefetch();
            }
          }
        },
      }
    );
  };

  const handlePrint = useReactToPrint({
    content: () => (tableRef && tableRef.current ? tableRef.current : null),
    pageStyle: `@page { size: letter landscape; margin-top: 10mm; margin-left: 6.5mm; }`,
    onAfterPrint: () => {
      setRenderImage(false);
    },
  });

  const onSubmit = () => {
    if (watchIncludeImages) {
      setRenderImage(true);
    } else {
      const csvData = convertToCSV(getData(), columnsNames);
      const link = document.createElement("a");
      link.setAttribute("href", csvData);
      link.setAttribute(
        "download",
        `table_data_${new Date().toLocaleString()}.csv`
      );
      link.click();
    }
  };
  const buttons = [
    {
      label: <FormattedMessage id="TABLE.BUTTON.EDIT" defaultMessage="Edit" />,
      onClick: (
        setSelectedRow: (row?: Row<DataType>) => void,
        row?: Row<DataType>
      ) => {
        row &&
          handleRowActionClick(
            setShowModal,
            setSelected,
            setSelectedRow,
            row,
            response?.data
          );
      },
      icon: EditIcon,
      disabled: true,
    },
  ];

  return (
    <div className={styles.studies}>
      <div>
        <div>
          <Form
            className="my-2 justify-content-end d-flex"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={`d-flex ${styles.mainBox}`}>
              <FormInput
                type="text"
                name="patient"
                control={control}
                input={{
                  placeholder: "SubjectId",
                  className: styles.inputPatient,
                }}
              />
              <div className={styles.input}>
                <FormInput
                  type="select"
                  name="study"
                  control={control}
                  input={{
                    placeholder: intl.formatMessage({
                      id: "STUDY",
                      defaultMessage: "Study",
                    }),
                    items: getStudies(),
                    className: styles.inputStudy,
                  }}
                />
              </div>
              <div className="ms-3 mb-2">
                <div className={styles.checkboxText}>Include Images</div>
                <div className={styles.checkboxBox}>
                  <FormInput
                    type="checkbox"
                    name="includeImages"
                    control={control}
                  />
                </div>
              </div>
              <div>
                <Button
                  variant="outline-dark"
                  onClick={() =>
                    onFilterSubmit({
                      study: watchStudy,
                      includeImages: watchIncludeImages,
                      patient: watchPatient,
                    })
                  }
                  className="border-dark"
                >
                  <FormattedMessage
                    id="BUTTON.SEARCH"
                    defaultMessage="Search"
                  />
                </Button>
              </div>
              <Button
                type="submit"
                variant="outline-dark"
                className="border-dark"
              >
                <FormattedMessage
                  id="ENVIVO.REPORTS.GENERATE"
                  defaultMessage="Generate"
                />
              </Button>
            </div>
          </Form>
        </div>
        <Table
          ref={tableRef}
          columns={getColumns("ENVIVO.REPORTS.TABLE.COLUMN", columnsNames)}
          data={getData()}
          buttons={buttons}
          className={styles.table}
          tableHooks={[useExpanded, usePagination]}
          renderRowSubComponent={(row, table) => (
            <ExpandedRow
              row={row}
              table={table}
              includeImages={watchIncludeImages}
              watch={watch}
            />
          )}
        />
        {showModal && selected && (
          <SampleCapscanModal
            {...{
              id: selected.id,
              sampleNumber: selected.sampleNumber,
              suffix: selected.suffix,
              volume: selected.volume,
              pH: selected.pH,
              imageUrl: selected.imageUrl,
              bodyLocation: selected.bodyLocation,
              capscanSn: selected.capscanSn,
              handleHide: () => setShowModal(false),
              refetch: filterRefetch,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CapscanSampleReportTable;
