import {FullSampleDataDto} from "../../api";

export type FilterSamplesResponse = {
    imageUrl?: string;
    studyId: string;
    clinicName: string;
    clinicDepartmentName: string;
    StudyName: string;
    sampleType: SampleType;
    sampleNumber: string;
    kitSubjectId:string;
    processCollectionDate: Date;
    ProcessingDate: Date;
    temperature: number;
    stoolScore: number;
    note: string;
};

export type FilterSamplesTestResponse = {
    statusCode: number;
    success: boolean;
    message: string;
    data: FullSampleDataDto[];
};


export enum SampleType {
    stool,
    saliva,
    plasma,
}
