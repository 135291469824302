import { useState, useEffect, useCallback } from "react";
import { Control, UseFormGetValues, useForm } from "react-hook-form";
import { CapScan, KitResponse } from "../../../models/responses/kit.response";
import EditableCell from "../../../components/EditTable/EditableCell/EditableCell";
import { MetadataModal } from "./Metadata/MetaData";
import Status, { statuses } from "../../../components/Status/Status";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "../../../components/Button/Button";
import { FormattedMessage } from "react-intl";
import useKit from "../../../hooks/useKit";
import { makeDirtyObject } from "../../../util/form.util";
import { getFormattedDate, getFormattedLocalDate, getTotalHoursBetweenDates, setPropertiesOfDate } from "../../../util/date.util";
import { SubjectMetadata } from "../../../api/models/subject-metadata";
import FormInput from "../../../components/form/FormInput/FormInput";
import { sexList } from "./Metadata/General";
import { getCapscanColor } from "../../../util/shared.util";
import { OtherSamplesSubTable } from "./OtherSamplesSubTable";
import { CreateSampleDto, CreateSampleDtoSampleTypeEnum, KitSampleDto, UpdateSampleDto } from "../../../api";
import useProcessOther from "../../../hooks/useProcessOther";
import { SampleType } from "../../../models/responses/sampleReports.response";
import useProcessSample from "../../../hooks/useProcessSample";
import { IApiResult } from "../../../models/responses/api-result.response";

type ClinicalRowProps = {
  item: KitResponse;
  studyName: string;
  refetch: () => void;
};
export type ClinicalProps = {
  id: string;
  subjectId: string;
  metadata: SubjectMetadata;
  capscans: CapscanProps[];
  originalCapscans: originalCapscansProps[];
  status: number;
  comment: string;
  salivaSamples: SampleProps[];
  plasmaSamples: SampleProps[];
  originalSamples: KitSampleDto[];
};

type originalCapscansProps = {
  id: string;
  sn: string;
  ingestedDate: Date;
  recoveredDate: Date;
  storageDate: Date;
  temperature: number;
  waterAdded: boolean;
  reference: string;
};

type CapscanProps = {
  ingestedDate: string;
  ingestedTime: string;
  recoveredDate: string;
  recoveredTime: string;
  transitTime: string;
  storageDate: string;
  storageTime: string;
  temperature: number;
  waterAdded: boolean;
  reference: string;
};

export type SampleProps = {
  id: string;
  processCollectionDate: string;
  processCollectionDateTime: string;
  storageDate: string;
  storageTime: string;
  temperature: number;
  sampleType: SampleType;
  sampleNumber: number;
};

type CapscansSubTableProps = {
  capscans: CapScan[];
  control: Control<ClinicalProps, any>;
  getValues: UseFormGetValues<ClinicalProps>;
};

export const kitStatuses = [
  statuses.NOT_ASSIGNED,
  statuses.ASSIGNED_TO_PATIENT,
  statuses.SAMPLING_COMPLETED,
  statuses.RETURN_UNUSED_KIT,
  statuses.MARK_AS_LOST,
  statuses.SAMPLE_SENT_TO_ENVIVO,
  statuses.DESTROY,
];

const CapscansSubTable = ({ capscans, control }: CapscansSubTableProps) => {
  const len = capscans.length;
  return len > 0 ? (
    <>
      <td width={"1%"} className="border-end border-start border-dark">
        {capscans.map((_, index) => (
          <div>{`${index + 1}/${capscans.length}`}</div>
        ))}
      </td>
      <td width={"10%"}>
        {capscans.map(({ ingestedDate }, index) => (
          <EditableCell defaultValue={ingestedDate && getFormattedLocalDate(ingestedDate, "YYYY-MM-DD")} type="date" name={`capscans.${index}.ingestedDate`} control={control} />
        ))}
      </td>
      <td width={"5%"} className="border-end border-dark">
        {capscans.map(({ ingestedDate }, index) => (
          <EditableCell defaultValue={ingestedDate && getFormattedLocalDate(ingestedDate, "HH:mm")} type="time" name={`capscans.${index}.ingestedTime`} control={control} />
        ))}
      </td>
      <td width={"10%"}>
        {capscans.map(({ recoveredDate }, index) => (
          <EditableCell defaultValue={recoveredDate && getFormattedLocalDate(recoveredDate, "YYYY-MM-DD")} type="date" name={`capscans.${index}.recoveredDate`} control={control} />
        ))}
      </td>
      <td width={"5%"} className="border-end border-dark">
        {capscans.map(({ recoveredDate }, index) => (
          <EditableCell defaultValue={recoveredDate && getFormattedLocalDate(recoveredDate, "HH:mm")} type="time" name={`capscans.${index}.recoveredTime`} control={control} />
        ))}
      </td>
      <td width={"5%"} className="border-end border-dark">
        {capscans.map(({ recoveredDate, ingestedDate }) => {
          if (recoveredDate && ingestedDate) {
            return <div>{`${getTotalHoursBetweenDates(ingestedDate, recoveredDate)} hrs`}</div>;
          }
          return null;
        })}
      </td>
      <td width={"10%"}>
        {capscans.map(({ storageDate }, index) => {
          return <EditableCell defaultValue={storageDate && getFormattedLocalDate(storageDate, "YYYY-MM-DD")} type="date" name={`capscans.${index}.storageDate`} control={control} />;
        })}
      </td>
      <td width={"5%"} className="border-end border-dark">
        {capscans.map(({ storageDate }, index) => (
          <EditableCell defaultValue={storageDate && getFormattedLocalDate(storageDate, "HH:mm")} type="time" name={`capscans.${index}.storageTime`} control={control} />
        ))}
      </td>
      <td width={"5%"} className="border-end border-dark">
        {capscans.map(({ temperature }, index) => (
          <EditableCell defaultValue={temperature} type="number" name={`capscans.${index}.temperature`} control={control} min={-80} max={-20} />
        ))}
      </td>
      <td width={"1%"} className="border-end border-dark">
        {capscans.map(({ waterAdded }, index) => (
          <div className="ms-3 mb-2">
            <FormInput type="checkbox" name={`capscans.${index}.waterAdded`} control={control} defaultValue={waterAdded || false} />
          </div>
        ))}
      </td>
      <td width={"6%"} className="border-end border-dark">
        {capscans.map(({ reference }, index) => {
          if (!reference) {
            return <></>;
          }
          const color = getCapscanColor(reference);
          return (
            <div>
              {reference} <FormattedMessage id={`"CLINICAL.CAPSCAN.${color.toUpperCase()}`} defaultMessage={color} />
            </div>
          );
        })}
      </td>
    </>
  ) : (
    <td colSpan={11} />
  );
};

const ClinicalRow = ({ item, studyName, refetch }: ClinicalRowProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { dirtyFields, isValid, isDirty },
    watch,
    getFieldState,
  } = useForm<ClinicalProps>({
    defaultValues: {
      ...item,
      originalCapscans: item.capscans.map((capscan) => ({
        ...capscan,
      })),
      capscans: undefined,
      originalSamples: item.samples,
    },
  });

  const watchAllFields = watch();
  const [isFormValid, setIsFormValid] = useState<boolean | undefined | "">();
  const { edit } = useKit();
  const { addProcess } = useProcessOther();
  const { updateProcessSample } = useProcessSample();

  const getMetadataString = () => {
    if (item.metadata) {
      const { sex, dateOfBirth, weight, height } = item.metadata;
      const sexString = sex !== undefined ? sexList[sex] : "";
      const weightString = weight ? `${weight} KG` : "";
      const heightString = height ? `${height} CM` : "";
      return `${sexString} 
      ${dateOfBirth ?? ""}
      ${weightString}
      ${heightString}`;
    }
  };

  const validDate = useCallback(
    (index: number, date: boolean | undefined, time: boolean | undefined, name: "recovered" | "ingested" | "storage") => {
      const dateName: "recoveredDate" | "ingestedDate" | "storageDate" = `${name}Date`;
      const timeName: "recoveredTime" | "ingestedTime" | "storageTime" = `${name}Time`;

      if (item.capscans[index][dateName]) {
        return !(getValues(`capscans.${index}.${dateName}`) === "" || getValues(`capscans.${index}.${timeName}`) === "");
      }
      return (date && time) || (!date && !time);
    },
    [getValues, item.capscans]
  );

  const validateForm = useCallback(() => {
    const validDates = dirtyFields.capscans?.filter((capscan, index) => {
      if (!capscan) {
        return true;
      }
      if (Object.keys(capscan).length === 1 && capscan.hasOwnProperty("transitTime")) return true;
      const validRecovered = validDate(index, capscan.recoveredDate, capscan.recoveredTime, "recovered");
      const validIngested = validDate(index, capscan.ingestedDate, capscan.ingestedTime, "ingested");
      const validStorage = validDate(index, capscan.storageDate, capscan.storageTime, "storage");
      return validRecovered && validIngested && validStorage;
    });
    const subjectId = item.subjectId || getValues("subjectId");
    const dates = validDates ? validDates.length === (dirtyFields.capscans && dirtyFields.capscans?.filter((item) => item).length) : true;
    return isValid && subjectId && dates;
  }, [dirtyFields.capscans, getValues, isValid, item.subjectId, validDate]);

  const setNewOtherSamples = async (samples: SampleProps[]) => {
    let suffix = ["A", "B", "C"];
    const newSamples = samples.map((s) => {
      const sample: CreateSampleDto = {
        sampleNumber: Date.now(),
        sampleType: s.sampleType as unknown as CreateSampleDtoSampleTypeEnum,
        kitSubjectId: item.subjectId,
        suffix: [{ suffix: suffix[s.sampleType], volume: "" }],
      };
      const { processCollectionDate, processCollectionDateTime, storageDate, storageTime, temperature } = s;

      if (processCollectionDate || processCollectionDateTime) {
        sample.processCollectionDate = new Date(setPropertiesOfDate(processCollectionDate?.toString(), getFormattedDate(processCollectionDate), processCollectionDateTime));
      }

      if (storageDate || storageTime) {
        sample.processCollectionDate = new Date(setPropertiesOfDate(storageDate?.toString(), getFormattedDate(storageDate), storageTime));
      }

      if (temperature) {
        sample.temperature = Number(temperature);
      }
      return sample;
    });

    const response = await addProcess({
      samples: newSamples.filter((s) => s.processCollectionDate || s.storageDate || s.temperature),
      kitBarcode: item.kitBarcode,
    });
    return response;
  };

  const setUpdateOtherSamples = async (samples: SampleProps[]) => {
    const updatedSamples = samples.map((s, idx) => {
      const sample: UpdateSampleDto = {
        id: s.id,
        kitBarcode: item.kitBarcode,
      };
      const { processCollectionDate, processCollectionDateTime, storageDate, storageTime, temperature } = s;

      if (processCollectionDate || processCollectionDateTime) {
        sample.processCollectionDate = new Date(setPropertiesOfDate(processCollectionDate?.toString(), getFormattedDate(processCollectionDate), processCollectionDateTime));
      }

      if (storageDate || storageTime) {
        sample.storageDate = new Date(setPropertiesOfDate(storageDate?.toString(), getFormattedDate(storageDate), storageTime));
      }

      if (temperature) {
        sample.temperature = Number(temperature);
      }
      return sample;
    });
    let res: IApiResult<void> | null = null;
    for (let index = 0; index < updatedSamples.length; index++) {
      res = await updateProcessSample(updatedSamples[index]);
      if (!res?.success) {
        return res;
      }
    }

    return res;
  };

  const onsubmit = async (formState: ClinicalProps) => {
    const { id, originalCapscans, ...dirtyState } = formState;

    const updatedCapscans: CapScan[] = [];
    const dirtyObject = makeDirtyObject((key: keyof ClinicalProps) => getFieldState(key).isDirty, dirtyState);
    const { capscans } = dirtyObject;
    if (capscans) {
      capscans.forEach((capscanToUpdate, index) => {
        const capscan: CapScan = {
          id: originalCapscans[index].id,
          sn: originalCapscans[index].sn,
        };
        const { ingestedDate, ingestedTime, recoveredDate, recoveredTime, storageDate, storageTime, temperature, waterAdded } = capscanToUpdate;

        if (ingestedDate || ingestedTime) {
          capscan.ingestedDate = new Date(setPropertiesOfDate(originalCapscans[index]?.ingestedDate?.toString(), getFormattedDate(ingestedDate), ingestedTime));
        }
        if (recoveredDate || recoveredTime) {
          capscan.recoveredDate = new Date(
            setPropertiesOfDate(
              originalCapscans[index].recoveredDate?.toString(),
              recoveredDate,
              recoveredTime
            )
          );
        }
        if (storageDate || storageTime) {
          capscan.storageDate = new Date(setPropertiesOfDate(originalCapscans[index].storageDate?.toString(), getFormattedDate(storageDate), storageTime));
        }
        if (temperature) {
          capscan.temperature = Number(temperature);
        }
        capscan.waterAdded = waterAdded;

        updatedCapscans.push({ ...originalCapscans[index], ...capscan });
      });
    }
    const response = await edit({
      ...dirtyObject,
      id,
      ...(capscans && { capscans: updatedCapscans }),
    });

    const { salivaSamples, plasmaSamples } = dirtyObject;

    const newSalivaResponse = await setNewOtherSamples(salivaSamples.filter((s) => !!!s.id));
    const newPlasmaResponse = await setNewOtherSamples(plasmaSamples.filter((s) => !!!s.id));

    const UpdatedSalivaResponse = await setUpdateOtherSamples(salivaSamples.filter((s) => s.id));
    const UpdatedPLasmaResponse = await setUpdateOtherSamples(plasmaSamples.filter((s) => s.id));

    if (response?.success && newSalivaResponse?.success && newPlasmaResponse?.success && UpdatedSalivaResponse?.success && UpdatedPLasmaResponse?.success) {
      refetch();
    }
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [watchAllFields, validateForm]);

  return (
    <tr>
      <td width={"5%"} className="border-end border-dark">
        <EditableCell defaultValue={item.subjectId} type={"text"} control={control} name="subjectId" required={true} />
      </td>
      <td width={"5%"} className="border-end border-dark">
        {item.kitBarcode}
      </td>
      <td width={"12%"} className="border-end border-dark">
        <EditableCell
          defaultValue={getMetadataString()}
          type={"modal"}
          control={control}
          name="metadata"
          renderModal={(setShow) => <MetadataModal studyName={studyName} tid={item.subjectId} metadata={{ kitBarcode: item.kitBarcode, ...item.metadata }} setShow={setShow} refetch={refetch} />}
        />
      </td>
      <CapscansSubTable key={`capscan ${item.id}`} capscans={item.capscans} control={control} getValues={getValues} />
      <OtherSamplesSubTable sampleType={SampleType.saliva} setValue={setValue} key={`otherSample_saliva ${item.id}`} kitItem={item} control={control} capscans={item.capscans} refetch={refetch} />
      <OtherSamplesSubTable sampleType={SampleType.plasma} setValue={setValue} key={`otherSample_plasma ${item.id}`} kitItem={item} control={control} capscans={item.capscans} refetch={refetch} />

      <td className="border-end border-dark">
        <Status status={kitStatuses[getValues("status")]} />
      </td>
      <td width={"12%"} className="border-end border-dark">
        <EditableCell defaultValue={item.comment} type={"text"} control={control} name="comment" />
      </td>
      <td className="border-end border-dark">
        <DropdownButton drop="start" variant="light" title="">
          {kitStatuses.map(
            (status, index) =>
              status !== statuses.DESTROY && (
                <Dropdown.Item
                  eventKey={index}
                  key={index}
                  onClick={() => {
                    setValue("status", index, { shouldDirty: true });
                  }}
                >
                  <Status status={status} />
                </Dropdown.Item>
              )
          )}
        </DropdownButton>
      </td>

      {isDirty && (
        <td>
          <Button variant="success" onClick={handleSubmit(onsubmit)} disabled={!isFormValid}>
            <FormattedMessage id="BUTTON.SAVE" defaultMessage="Save" />
          </Button>
        </td>
      )}
    </tr>
  );
};

export default ClinicalRow;
